*{
    padding: 0;
    margin: 0;
}
.CameraVideo{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &>.Options{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background: rgba(0,0,0,0.35);
    }
}